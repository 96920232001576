<template>
    <vx-card title="Stock Transfer Order Planner">
        <div class="vx-input-group flex">
            <vs-tabs v-if="render && settingValue === 'Auto'" v-model="$route.params.status">
                <vs-tab label="Planning">
                    <tab-planning></tab-planning>
                </vs-tab>
                <vs-tab label="Suggestion" disabled>
                    <tab-suggestion></tab-suggestion>
                </vs-tab>
                <vs-tab label="Process" disabled>
                    <tab-process></tab-process>
                </vs-tab>
                <vs-tab label="Handover">
                    <tab-handover></tab-handover>
                </vs-tab>
                <vs-tab label="Complete">
                    <tab-complete></tab-complete>
                </vs-tab>
            </vs-tabs>

            <vs-tabs v-if="render && settingValue === 'Standart'" v-model="$route.params.status">
                <vs-tab label="Planning">
                    <tab-planning></tab-planning>
                </vs-tab>
                <vs-tab label="Suggestion">
                    <tab-suggestion></tab-suggestion>
                </vs-tab>
                <vs-tab label="Process">
                    <tab-process></tab-process>
                </vs-tab>
                <vs-tab label="Handover">
                    <tab-handover></tab-handover>
                </vs-tab>
                <vs-tab label="Complete">
                    <tab-complete></tab-complete>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>

<script>
import TabPlanning from "./TabPlanning.vue";
import TabSuggestion from "./TabSuggestion.vue";
import TabProcess from "./TabProcess.vue";
import TabHandover from "./TabHandover.vue";
import TabComplete from "./TabComplete.vue";

export default {
    components: {
        TabPlanning,
        TabSuggestion,
        TabProcess,
        TabHandover,
        TabComplete,
    },
    data() {
        return {
            activeTab: "Approved",
            tabStatus: "",
            render: true,
            settingValue: "",
        }
    },
    methods: {},
    mounted() {
        this.$vs.loading();
        this.$http
            .get("/api/wms/v1/outbound/sto-planner/setting-value")
            .then((resp) => {
                if (resp.code == 200) {
                    this.settingValue = resp.data
                }

                this.render = false
                this.$nextTick(() => this.render = true)
                this.$vs.loading.close();
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
            });
    },
}
</script>

<!-- // test -->