<template>
    <div>
        <vs-table 
            search 
            stripe 
            border 
            description 
            :sst="true" 
            :data="table.data" 
            :max-items="table.length"
            :total="table.total" 
            @search="handleSearch" 
            @change-page="handleChangePage" 
            @sort="handleSort"
            >
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template v-if="status == 'Planning'" slot="thead">
                <vs-th sort-key="">
                    <vs-checkbox @click.stop="addAllChecked()" v-model="checkedAll">Select All</vs-checkbox>
                </vs-th>
                <vs-th sort-key="">Inter Warehouse (STO)</vs-th>
                <vs-th sort-key="">Dest. Warehouse</vs-th>
                <vs-th sort-key="">Delivery Date (ETD)</vs-th>
                <vs-th sort-key="">ETA</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
            </template>
            <template v-if="status == 'Suggestion'" slot="thead">
                <vs-th sort-key="">
                    <vs-checkbox @click.stop="addAllChecked()" v-model="checkedAll">Select All</vs-checkbox>
                </vs-th>
                <vs-th sort-key="">STO Data</vs-th>
                <vs-th sort-key="">Picking List Data</vs-th>
                <vs-th sort-key="">Dest. Warehouse</vs-th>
                <vs-th sort-key="">Dest. Address</vs-th>
                <vs-th sort-key="">Delivery Date</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">ETA</vs-th>
            </template>
            <template v-if="status == 'Process'" slot="thead">
                <vs-th sort-key="">Action</vs-th>
                <vs-th sort-key="">STO Data</vs-th>
                <vs-th sort-key="">Picking List Data</vs-th>
                <vs-th sort-key="">Dest. Warehouse</vs-th>
                <vs-th sort-key="">Dest. Address</vs-th>
                <vs-th sort-key="">Delivery Date</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">ETA</vs-th>
            </template>
            <template v-if="status == 'Handover'" slot="thead">
                <vs-th sort-key="">Action</vs-th>
                <vs-th sort-key="">STO Data</vs-th>
                <vs-th sort-key="">Picking List Data</vs-th>
                <vs-th sort-key="">Handover Document</vs-th>
                <vs-th sort-key="">Dest. Warehouse</vs-th>
                <vs-th sort-key="">Address</vs-th>
                <vs-th sort-key="">ETA</vs-th>
            </template>
            <template v-if="status == 'Complete'" slot="thead">
                <vs-th sort-key="">Action</vs-th>
                <vs-th sort-key="">STO Data</vs-th>
                <vs-th sort-key="">Handover Document</vs-th>
                <vs-th sort-key="">Dest. Warehouse</vs-th>
                <vs-th sort-key="">Delivery Date</vs-th>
                <vs-th sort-key="">ETA</vs-th>
                <vs-th sort-key="">Receive Date</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="vx-input-group flex">
                            <vs-checkbox
                                v-if="status == 'Planning'"
                                :checked="checked.includes(tr.id)"
                                v-on:click="addChecked(tr.id)"
                            ></vs-checkbox>
                            <vs-checkbox
                                v-if="status == 'Suggestion'"
                                :checked="checked.includes(tr.id)"
                                v-on:click="addChecked(tr.id)"
                            ></vs-checkbox>
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-edit" 
                                v-if="status == 'Suggestion'" title="Edit" style="margin-right: 5px" @click="handleEdit(tr.id)" />
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-eye"
                                v-if="status == 'Process'" title="Detail" style="margin-right: 5px" @click="handleDetail(tr.id)" />
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-eye"
                                v-if="status == 'Handover'" title="Detail" style="margin-right: 5px" @click="handleDetail(tr.id)" />
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-eye"
                                v-if="status == 'Complete'" title="Detail" style="margin-right: 5px" @click="handleDetail(tr.id)" />
                        </div>
                    </vs-td>
                    <vs-td v-if="status == 'Planning'" sort-key="">{{ tr.code }}</vs-td>
                    <vs-td v-if="status == 'Planning'" sort-key="">{{ tr.dest_warehouse_name }}</vs-td>
                    <vs-td v-if="status == 'Planning'" sort-key="">{{ tr.date }}</vs-td>
                    <vs-td v-if="status == 'Planning'" sort-key="">{{ tr.eta }}</vs-td>
                    <vs-td v-if="status == 'Planning'" sort-key="">{{ tr.total_sku }}</vs-td>

                    <vs-td v-if="status == 'Suggestion'" sort-key="">{{ tr.code }}</vs-td>
                    <vs-td v-if="status == 'Suggestion'" sort-key="">{{ tr.pick_code }}</vs-td>
                    <vs-td v-if="status == 'Suggestion'" sort-key="">{{ tr.dest_warehouse_name }}</vs-td>
                    <vs-td v-if="status == 'Suggestion'" sort-key="">{{ tr.dest_warehouse_address }}</vs-td>
                    <vs-td v-if="status == 'Suggestion'" sort-key="">{{ tr.date }}</vs-td>
                    <vs-td v-if="status == 'Suggestion'" sort-key="">{{ tr.total_sku }}</vs-td>
                    <vs-td v-if="status == 'Suggestion'" sort-key="">{{ tr.eta }}</vs-td>

                    <vs-td v-if="status == 'Process'" sort-key="">{{ tr.code }}</vs-td>
                    <vs-td v-if="status == 'Process'" sort-key="">{{ tr.pick_code }}</vs-td>
                    <vs-td v-if="status == 'Process'" sort-key="">{{ tr.dest_warehouse_name }}</vs-td>
                    <vs-td v-if="status == 'Process'" sort-key="">{{ tr.dest_warehouse_address }}</vs-td>
                    <vs-td v-if="status == 'Process'" sort-key="">{{ tr.date }}</vs-td>
                    <vs-td v-if="status == 'Process'" sort-key="">{{ tr.total_sku }}</vs-td>
                    <vs-td v-if="status == 'Process'" sort-key="">{{ tr.eta }}</vs-td>

                    <vs-td v-if="status == 'Handover'" sort-key="">{{ tr.code }}</vs-td>
                    <vs-td v-if="status == 'Handover'" sort-key="">{{ tr.pick_code }}</vs-td>
                    <vs-td v-if="status == 'Handover'" sort-key="">{{ tr.handover_code }}</vs-td>
                    <vs-td v-if="status == 'Handover'" sort-key="">{{ tr.dest_warehouse_name }}</vs-td>
                    <vs-td v-if="status == 'Handover'" sort-key="">{{ tr.eta }}</vs-td>

                    <vs-td v-if="status == 'Complete'" sort-key="">{{ tr.code }}</vs-td>
                    <vs-td v-if="status == 'Complete'" sort-key="">{{ tr.handover_code }}</vs-td>
                    <vs-td v-if="status == 'Complete'" sort-key="">{{ tr.dest_warehouse_name }}</vs-td>
                    <vs-td v-if="status == 'Complete'" sort-key="">{{ tr.dest_warehouse_address }}</vs-td>
                    <vs-td v-if="status == 'Complete'" sort-key="">{{ tr.eta }}</vs-td>
                    <vs-td v-if="status == 'Complete'" sort-key="">Not yet</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
        <vs-button v-if="status == 'Planning'" color="primary" @click="handlePlan()">PLAN</vs-button>
        <vs-button v-if="status == 'Suggestion'" color="primary" @click="handleRelease()">RELEASE</vs-button>
    </div>
</template>

<script>
import moment from "moment";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        stoNumberId: {
            type: Number,
        },
        destWarehouseId: {
            type: Number,
        },
        transactionDate: {
            type: Date,
            default: null,
        },
        status: {
            type: String,
            default: "Planning",
        },
    },
    data() {
        return {
            deleteId: null,
            action: null,
            table: this.tableDefaultState(),
            checked: [],
            checkedAll: false,
            selectedRouteAssignmentToAssign: [],
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.getData();
            this.$emit("close");
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/outbound/sto-planner", {
                    params: {
                        length: this.table.length,
                        page: this.table.page,
                        search: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        sto_number_id: (this.stoNumberId) 
                            ? this.stoNumberId 
                            : null,
                        dest_warehouse_id: (this.destWarehouseId) 
                            ? this.destWarehouseId 
                            : null,
                        status: this.status,
                        transaction_date: (this.transactionDate) 
                            ? moment(this.transactionDate).format("YYYY-MM-DD") 
                            : null,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.checkedAll = false;
                        this.checked = [];
                        this.selectedRouteAssignmentToAssign = [];

                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleDelete(id) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Please confirm to delete this data",
                accept: this.acceptDelete,
            });
        },
        acceptDelete() {
            this.$vs.loading();
            this.$http
                .delete("/api/wms/v1/outbound/sto-planner/" + this.deleteId)
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "The data was successfully deleted",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                    this.getData();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        handleApprovalRequest(id) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Please confirm to request approval this data",
                accept: this.actionApprovalRequest,
            });
        },
        actionApprovalRequest() {
            this.$vs.loading();
            this.$http
                .post("/api/wms/v1/approval/sto/send-approval/" + this.deleteId)
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "The data was success request to approve, check waiting approval tab.",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                    this.getData();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        handleEdit(id) {
            this.$router.push({
                name: "outbound.stock-transfer-order-planner-suggestion-detail",
                params: { id: id },
            });
        },
        handleDetail(id) {
            if (this.status == 'Process') {
                this.$router.push({
                    name: "outbound.stock-transfer-order-planner-process-detail",
                    params: { id: id },
                });
            } else if (this.status == 'Handover') {
                this.$router.push({
                    name: "outbound.stock-transfer-order-planner-handover-detail",
                    params: { id: id },
                });
            } else if (this.status == 'Complete') {
                this.$router.push({
                    name: "outbound.stock-transfer-order-planner-complete-detail",
                    params: { id: id },
                });
            }
        },
        handleCancel(id) {
            this.action = "cancel";
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to cancel this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .post("/api/wms/v1/outbound/sto-planner/change-stat/" + this.deleteId + "/" + this.action)
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully canceled",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }

                            this.$vs.loading.close();
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                },
            });
        },
        handleExecute(id) {
            this.action = "execute";
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to execute this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .post("/api/wms/v1/outbound/sto-planner/change-stat/" + this.deleteId + "/" + this.action)
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully executed",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }

                            this.$vs.loading.close();
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                },
            });
        },
        addChecked(val) {
            if (this.checked.includes(val)) {
                this.checked.splice(this.checked.indexOf(val), 1);
            } else {
                this.checked.push(val);
            }
        },
        addAllChecked() {
            if (this.checkedAll == false) {
                for (var i = 0; i < this.table.data.length; i++) {
                    if (this.checked.includes(this.table.data[i].id)) {
                        //
                    } else {
                        this.checked.push(this.table.data[i].id);
                    }
                }
            } else {
                for (var j = 0; j < this.table.data.length; j++) {
                    this.checked.splice(this.checked.indexOf(this.table.data[j].id), 1);
                }
            }
        },
        handlePlan() {
            if (this.checked.length) {
                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Confirm`,
                    text: "Are you sure to process these data(s)?",
                    accept: () => {
                        this.$vs.loading();
                        this.$http
                            .post("/api/wms/v1/outbound/sto-planner/picking-plan", {
                                sto_ids: this.checked
                            })
                            .then(resp => {
                                if (resp.code == 200) {
                                    this.$vs.notify({
                                        title: "Success",
                                        text: "Document is processed",
                                        color: "success",
                                        position: "top-right",
                                        iconPack: "feather",
                                        icon: "icon-check"
                                    });
                                    this.$vs.loading.close();
                                } else {
                                    this.$vs.notify({
                                        title: "Danger",
                                        text: resp.message,
                                        color: "danger",
                                        position: "top-right",
                                        iconPack: "feather",
                                        icon: "icon-check"
                                    });
                                    // this.$vs.loading.close();
                                }
                                this.getData();
                            })
                            .catch((error) => {
                                this.$vs.loading.close();
                                console.log(error);
                            });
                    }
                });
            } else {
                this.$vs.notify({
                    title: "Danger",
                    text: "Please check minimal 1 STO",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            }
        },
        handleRelease() {
            if (this.checked.length) {
                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Confirm`,
                    text: "Are you sure to release these datas?",
                    accept: () => {
                        this.$vs.loading();
                        this.$http
                            .patch("/api/wms/v1/outbound/sto-planner/release-suggestion", {
                                sto_ids: this.checked
                            })
                            .then(resp => {
                                if (resp.code == 200) {
                                    this.$vs.notify({
                                        title: "Success",
                                        text: "Datas is released",
                                        color: "success",
                                        position: "top-right",
                                        iconPack: "feather",
                                        icon: "icon-check"
                                    });
                                    this.$vs.loading.close();
                                } else {
                                    this.$vs.notify({
                                        title: "Danger",
                                        text: resp.message,
                                        color: "danger",
                                        position: "top-right",
                                        iconPack: "feather",
                                        icon: "icon-check"
                                    });
                                    this.$vs.loading.close();
                                }
                                this.getData();
                            })
                            .catch((error) => {
                                this.$vs.loading.close();
                                console.log(error);
                            });
                    }
                });
            } else {
                this.$vs.notify({
                    title: "Danger",
                    text: "Please check minimal 1 STO",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            }
        }
    },
    mounted() {
        // ...
    },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
};
</script>
  